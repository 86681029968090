html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#appAuthentication{
  min-height: 100vh;
  /*display: grid;*/
  /*flex-direction: column;*/

}
.thinHeading{
  font-weight: 300;
}

.iconHeading{
  padding-left: 10px;
}

.heroSection{
  /*padding-top: 3%;*/
  /*padding-bottom: 3%;*/
  /*padding-left: 3%;*/
  /*padding-ri*/
  font-family: museo-sans,sans-serif;
  font-weight: 300;
  font-style: normal;
  padding: 3%;
  /*margin-top: 50px;*/
}

.uploadOuterSection{
  background-color: #0081ff;
  color: white;
  /*border-radius: 5px;*/
  height: 200px;
  padding-top: 1%;
  padding-left: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  resize: vertical;
  overflow: auto;
  margin-bottom: 2.5%;
  cursor: pointer;
}

.uploadInnerSection{
  border: 2px dashed #7F7F7F;
  background-color: #1b6ad0;
  height: 95%;
  width: 99%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  resize: vertical;
  overflow: auto;
  align-items: center;
  text-align: center;
}

.uploadLabel{
  height: 100%;
  width: 100%;
  text-align: center;
}

.uploadIcons{
  font-size: 300%;
  font-weight: 300;
}

.lightgray{
  background-color: lightgrey;
}

.uploadProgressBar{
  width: 200%;
  /*text-align: center;*/
  margin-left: -50%;
}

.fileDropTarget{
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  resize: both;
  margin-bottom: 2%;
}

.fileDropSource{
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid white;
  border-radius: 5px;
  background-color: white;
  height: 90%;
  width: 90%;
}

.fileDropSourceDrag{
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid white;
  border-radius: 5px;
  background-color: white;
  height: 90%;
  width: 90%;
  -ms-transform: rotate(20deg); /* IE 9 */
  -webkit-transform: rotate(20deg); /* Safari */
  transform: rotate(20deg);
}

.linkStyle{
  color: blue;
  cursor: pointer;
}

.linkStyle:hover{
  text-underline: cornflowerblue;
  color: cornflowerblue;
}

.app{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.roundedButton{
  border-radius: 40px;
}

.downloadFileIndex{
  /*background-color: #ffdb99;*/
  /*border: 1px solid #ffdb99;*/
  border-radius: 5px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto auto;
  grid-template-areas:
          "header header"
          "fileName removeDuplicates"
          "downloadButton downloadButton";
}

.mergedFileNameDiv{
  grid-area: fileName;
  justify-self: end;
  margin-right: 10%;
  align-self: center;
}

.removeDuplicatesDiv{
  grid-area: removeDuplicates;
  margin-left: 5%;
  align-self: center;
  font-size: 120%;
}

.downloadButtonDiv{
  margin-top: 25px;
  margin-bottom: 25px;
  grid-area: downloadButton;
}

.downloadHeader{
  padding-top: 12px;
  padding-bottom: 20px;
  text-align: center;
  grid-area: header;
}

.editOptionDiv{
  padding-bottom: 10px;
}

.importSheetOptions{
  padding-top: 10px;
  padding-bottom: 10px;
}

.wordwrap{
  word-break: break-all;
  word-wrap: break-word;
}

.semanticBorder{
  margin: 0em;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  -webkit-transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.8);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}

.focusBorder{
  border-color: #85B7D9;
  background: #FFFFFF;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pricingPage{
  display: flex;
  flex-direction: column;
  text-align: center;
  /*grid-template-columns: 50% 50%;*/
  /*grid-template-rows: auto;*/
  /*grid-template-areas:*/
          /*"freePricingCard paidPricingCard";*/
  min-height: 100vh;
  padding-bottom: 100px;
  /*grid-column-gap: 25px;*/
}

.freePricingCard{
  grid-area: freePricingCard;
}

.paidPricingCard{
  grid-area: paidPricingCard;
}

.pricingCard{
  /*padding-left: 30%;*/
  /*padding-right: 30%;*/
  padding-top: 10%;
}

.desc {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.desc li {
  border-bottom: 1px solid #eee;
  padding: 10px;
  text-align: center;
}

.closeIconClass{
  height: 25px;
  width: 25px;
  float: right;
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
}

.closeIconClass:hover{
  background-color: #DCDCDC;
  border-radius: 50%;
}

.closeIconClass > i{
  padding: 0;
}

#dashboardIndex{
  /*display: grid;*/
  /*grid-template-columns: 150px 90vw;*/
  /*grid-template-rows: auto;*/
  /*grid-template-areas: "dashboardMenu dashboardDisplay";*/
  min-height: 100vh;
  padding: 0 50px 200px 50px;
  /*background-color: #f5f5f5;*/
  /*padding-bottom: 200px;*/
}

#dashboardProgressBar{
  padding-top: 50px;
}

#dashboardMenu{
  grid-area: dashboardMenu;
  /*background-color: black;*/
  padding-left: 10px;
  padding-top: 10px;
}

#dashboardDisplay{
  grid-area: dashboardDisplay;
}

.dashboardDisplayIndex{
  padding-top: 10px;
}

a.nostyle:link, a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
}

#uploadFileStepsDiv{
  margin-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
  /*height: 100px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  resize: both;
  overflow: auto;
}

.tutorialPopup{
  z-index: 500;
  position: fixed;
  bottom: 3%;
  right: 3%;
}

.urlBoxHeader{
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #343b43;
  color: lightgrey;
  border-radius: 8px 8px 0 0;
  font-size: 90%;
}

.urlBoxLink{
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #677685;
  color: white;
  border-radius: 0 0 8px 8px;
}

.tutorialPopupButton{
  background-color: #198c19;
    color: white;
  font-weight: bold;
  padding: 20px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    cursor: pointer;
  /*height: 100px;*/
  /*width: 50px;*/
}

#uploadFileSteps{
  text-align: center;
}

#uploadFilesBlock{
  margin-top: 30px;
}


#uploadFilesSampleTableContainer{
  width: 100%;
  overflow-y: auto;
  margin-top: 30px;
  padding-bottom: 30px;
}

#uploadFilesSampleTable{
  /*overflow-x: scroll;*/
  width: auto;
}

#uploadFilesSampleTableContainer, #uploadFilesSampleTable{
  transform:rotateX(180deg);
  -moz-transform:rotateX(180deg); /* Mozilla */
  -webkit-transform:rotateX(180deg); /* Safari and Chrome */
  -ms-transform:rotateX(180deg); /* IE 9+ */
  -o-transform:rotateX(180deg); /* Opera */
}

#editFileToolbarDiv{
  margin-top: 50px;
  display: grid;
  grid-template-columns: 67% 3% 30%;
  grid-row: auto;
  grid-template-areas: "editButton fileSummary removeFileButton";
}

#editButtonDiv{
  grid-area: editButton;
}

#fileInfoDiv{
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 15px 15px 15px 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}



#fileSummaryDiv{
  grid-area: fileSummary;
  text-align: center;
}

#removeFileButtonDiv{
  grid-area: removeFileButton;
  float: right;
}

#editFileModalBody{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-template-areas: "editOptions sheetsOptions";
  grid-column-gap: 25px;
}

#xlsEditOptionsDiv{
  grid-area: editOptions;
  margin-top: 10px;
  border-right: 1px black solid;
  margin-bottom: 50px;
}

#importSheetsDiv{
  grid-area: sheetsOptions;
  padding-top: 30px;
}

#editOptionsDiv{
  padding-top: 10px;
}

#selectColumn{
  cursor: pointer;
  height: 110%;
  width: 110%;
  text-align: center;
}

#cleanFunctionOptionsDiv{
  margin-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  resize: both;
  overflow: auto;
}

#cleanFunctionRow{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-template-areas: "smartClean customClean";
  grid-column-gap: 25px;
  text-align: center;
}

#cleanFunctionSmartClean{
  float: right;
  grid-area: smartClean;
}

#cleanFunctionCustomClean{
  grid-area: customClean;
}

#customOptionsDiv{
  height: auto;
  margin: 0 auto;
  margin-top: 30px;
  width: 50%;
}

.addSpan{
  cursor: pointer;
  border-radius: 25px;
  color: white;
  font-weight: 600;
  padding: 7px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.pageHero{
  color:white;
  text-align: center;
  background: url("../src/SharedComponents/BackgroundBanner.svg") no-repeat;
  background-size: 100% auto;
  padding-top: 40px;
  padding-bottom: 60px;
}

.landingPageHeroDiv{
  /*background: url("../src/LandingPage/wave.svg") no-repeat;*/
  /*background-size: 100% auto;*/
}

@media only screen and (max-width: 768px){
  .pageHero{
    background-size: cover;
    color: black;
  }

  .helpIndexDisplay{
    margin-left: 20px;
    margin-right: 20px;
  }
}

.contactUsIndex{
  min-height: 100vh;
  margin: 50px 20px 0 20px;
}


.landingPageBackground{
  font-family: museo-sans,sans-serif;
  /*font-weight: 300;*/
  font-style: normal;
  /*background: url("../src/SharedComponents/CleanMainBackground.svg") no-repeat;*/
  /*background-size: 100% auto;*/
}

.cardDiv{
  /*border: 1px black solid;*/
  margin: 15px;
  text-align: center;
  padding: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.pricingCardDiv{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px;
}

#simplePricingBlock{
  /*padding: 10px;*/
  width: 50%;
  margin: 0 auto;
  text-align: left;
}

#simplePricingBlockMobile{
  width: 90%;
  margin: 0 auto;
  text-align: left;
}

.pricingRow{
  padding-top: 15px;
  padding-bottom: 15px;
  /*border-top: 1px solid black;*/
  border-bottom: 1px solid lightgrey;
  padding-left: 10px;
}

.pricingRowTop{
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgrey;
  text-align: center;
  /*border-radius: 25px 25px 0px 0px;*/
  background-color: #7fd3fd;
  /*color: white;*/

}

.pricingRowBottom{
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid lightgrey;
  text-align: center;
}

.iconHeading{
  padding-left: 10px;
}

.footerShareGrid{
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  grid-template-rows: auto;
  grid-template-areas: "twitter facebook reddit linkedin email call";
}

.socialMediaButton{
  cursor: pointer;
}

#twitterButton{
  grid-area: twitter;
}

#facebookButton{
  grid-area: facebook;
}

#redditButton{
  grid-area: reddit;
}

#linkedinButton{
  grid-area: linkedin;
}

#emailButton{
  grid-area: email;
}

#callButton{
  grid-area: call;
}

.fa {
  padding: 10px;
  font-size: 15px;
  width: 15px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}

.fa-envelope{
  color: white;
  background: darkred;
}


.heroStepTop{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 10% 90%;
  grid-template-areas:
          "stepNumber stepIllustration";

  width: 40%;
  margin: 0 auto;

}

.heroStep{
  text-align: center;
}

.heroStepNumber{
  grid-area: stepNumber;
}

.heroStepIcon{
  grid-area: stepIllustration;
  margin-left: 35px;
}

.heroStepTopCenter{
  margin: 0 auto;
}

.heroStepCardDiv{
  /*border: 1px black solid;*/
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 5%;
  margin-right: 5%;
  padding: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #F5F5F5;
  /*z-index: 1;*/
  /*position: absolute;*/
}

/*.dividedLine{*/
  /*z-index: -999;*/
/*}*/
.landingPageFeatureRow{
  padding-top: 100px;
  padding-bottom: 150px;
}

#landingPageProblems{
  display: grid;
  grid-template-rows: 20% 20% 20% 20% 20%;
  grid-template-columns: 50% 50%;
  grid-template-areas:
          "prob11 prob12"
          "prob21 prob22"
          "prob31 prob31"
          "prob41 prob42"
          "prob51 prob52"
;
  grid-row-gap: 10px;
  grid-column-gap: 5px;
}

#problemsRow1Col1{
  grid-area: prob11
}

#problemsRow1Col2{
  grid-area: prob12
}

#problemsRow2Col1{
  grid-area: prob21
}

#problemsRow2Col2{
  grid-area: prob22
}

#problemsRow3Col1{
  grid-area: prob31
}

#problemsRow4Col1{
  grid-area: prob41
}

#problemsRow4Col2{
  grid-area: prob42
}

#problemsRow5Col1{
  grid-area: prob51
}

#problemsRow5Col2{
  grid-area: prob52
}

.landingPageProblem{
  font-style: italic;
  font-size: 125%;
  text-align: center;
  font-weight: 300;
  padding-right: 5px;
}

@media only screen and (min-width: 600px) {
  .landingPageProblem{
    font-style: italic;
    font-size: 125%;
    text-align: center;
    font-weight: 300;
    padding-right: 5px;
    padding-left: 5px;
  }
}

@media only screen and (min-width: 600px) {
  .deliveryCopy {
    height: 300px;
    font-size: 200%;
    /*font-style: italic;*/
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
}

.deliveryCopy{
  font-size: 200%;
  /*font-style: italic;*/
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.scheduleTimeForm{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /*margin: 15px;*/
  /*text-align: center;*/
  padding: 50px 30px 50px 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.scheduleTimeDiv{
  padding-top: 100px;
  /*background-color: black;*/
  /*color: white;*/
}

.calendarDisplay{
  color: black;
}

.dashboardDisplayHeader{
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: auto;
  grid-template-areas: "displayHeaderImage displayHeaderText";
  padding: 0;
}

#displayHeaderImage{
  grid-area: displayHeaderImage
}

#displayHeaderText{
  grid-area: displayHeaderText;
  margin-top: 9px;
}

@media only screen and (min-width: 768px){
  .navbarAuth{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: white;
  }
}

.intelligent-parsing-address-table{
  table-layout: fixed;
  width: auto;
  border: 1px solid lightgrey;
}

.intelligent-parsing-address-table > thead > tr{
  border: 1px solid lightgrey;
}

.intelligent-parsing-address-table td{
  border: 1px solid lightgrey;
  padding: 4px 5px 2px 5px;
}

.columnResultsNameInfoHover:hover{
  cursor: pointer;
  background-color: #0081ff;
  color: white;
  width: 100%;
  padding: 10px;
}

.multipleColumnNamesDropdown{
  /*position: relative;*/
  /*display: inline-block;*/
}

.multipleColumnNamesDropdownBtn{
  cursor: pointer;
}

/*.multipleColumnNamesDropdownContent{*/
  /*display: none;*/
  /*position: absolute;*/
  /*background-color: #f9f9f9;*/
  /*box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);*/
  /*z-index: 1;*/
/*}*/

/*.multipleColumnNamesDropdownBtn:hover .multipleColumnNamesDropdownContent{*/
  /*display: block;*/
/*}*/

.intelligent-parsing-address-table th{
  border: 1px solid lightgrey;
  padding: 4px 5px 2px 5px;
}

#feedbackDiv{
  width: 250px;
  font-size: 90%;
  background-color: whitesmoke;
}

.feedbackButtons{
  margin-top: 10px;
}

.feedbackResponseMessage{
  padding-top: 10px;
  width: 250px;
  text-align: center;
  font-size: 75%;
}

.cleanDataUploadButtonDiv{
  text-align: left;
  /*height: 150px;*/
  /*float: right;*/
  /*box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
}

#cleanIndex{
  min-height: 100vh;
  padding-top: 40px;
}

.cleanActionNotTaken{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

#columnSelectedTable{
  margin-top: 50px;
  /*border: 1px solid lightgrey;*/
  /*border-radius: 10px;*/
  /*box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

#columnSelectedTable th, td{
  padding: 8px 25px 8px 25px;
}

#columnSelectedTable th{
  border: 1px solid lightgrey;
  background-color: lightblue;
}

#columnSelectedTable td{
  border-bottom: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  font-size: 125%;
}

#cleanDisplay{
  display: grid;
  grid-template-columns: 70% 30%;
  /*grid-column-gap: 50px;*/
  grid-template-rows: auto;
  grid-template-areas: "cleanDisplayMasterTableResults cleanPayment";
  /*padding-right: 50px;*/
  border: 1px solid lightgrey;
}

#cleanDisplayPreview{
  grid-area: cleanDisplayPreview;
  /*margin-top: 50px;*/
  /*border: 1px solid lightgrey;*/
  /*border-radius: 15px;*/
  /*padding: 15px;*/
  /*box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.19);*/
}

#cleanDisplayColumnsSelected{
  grid-area: columnSelected;
}

#cleanDisplayPayment{
  grid-area: cleanPayment;
  border: 1px solid lightgrey;
}

.cleaningTotal{

}

.bigNumber{
  font-size: 175%;
  /*font-weight: bold;*/
}

.cleanPaymentDiv{
  padding: 15px 15px 15px 15px;
}

#cleanPaymentActionDiv{
  margin: 25px 0 25px 0;
  text-align: center;
}

#refillCreditsModalBody{
  padding: 25px 20px 75px 20px;
}

.cleanDisplayPreviewDisclaimer{
  border: 1px solid lightgrey;
  padding: 10px 0 10px 10px;
  color: white;
}

/*#3AE64C for total credits*/

.proDisclaimer{
  background-color: #fbb03b;
  color: black;
  /*background-color: #8F3DFF;*/
}

.basicDisclaimer{
  background-color: #a2d5fc;
  color: black;
  /*background-color: #002BEB;*/
}

#refillCreditsModalBodyGrid{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-template-areas: "refillCredits refillRates";
  /*grid-column-gap: 30px;*/
}

#refillCreditsModalBodyPay{
  grid-area: refillCredits;
  background-color: white;
  text-align: center;
  margin-right: 15px;
}

#refillCreditsModalBodyRates{
  grid-area: refillRates;
  background-color: white;
  margin-left: 15px;
}

.proEnoughCreditsBox{
  border: 1px solid lightgrey;
  padding: 5px;
  border-radius: 8px;
}

.segment-raised-rounded{
  /*border: 1px solid lightgrey;*/
  border-radius: 10px;
  padding: 15px;
  /*box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

#profileIndex{
  min-height: 100vh;
  padding: 50px 50px 200px 50px;
}

@media only screen and (max-width: 768px){
  #profileIndex{
    min-height: 100vh;
    padding: 15px;
  }
}

#profileIndexGridDiv{
  display: grid;
  grid-template-columns: 150px auto;
  grid-template-rows: auto;
  grid-template-areas: "profileIndexGridMenu profileIndexDisplayMenu";
  margin: 75px 100px 75px 100px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  background-color: #0081ff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#profileIndexGridMenuDiv{
  grid-area: profileIndexGridMenu;
  border-right: 1px solid lightgrey;

}

#profileIndexDisplayMenuDiv{
  grid-area: profileIndexDisplayMenu;
  padding: 20px 20px 20px 20px;
  background-color: #d8ecff;
}

#profileIndexMenu{
  color: white;
}

#profileIndexMenuItem{
  padding: 15px 10px 15px 15px;
  font-size: 125%;
  border-bottom: 1px solid lightgrey;
  cursor: pointer;
}

#creditPricingTable{
  color: gray;
}


.cleanDisplayMasterTable{
  display: grid;
  grid-template-columns: 20% auto;
  /*grid-template-rows: 20% auto;*/
  border: 1px solid lightgrey;
  /*border-radius: 10px;*/
  /*padding-top: 10px;*/
  /*overflow-x: scroll;*/
}

.cleanDisplayMasterMenu{
  background-color: #F8F8F8;
  /*border: 1px solid lightgrey;*/
  /*overflow-x: scroll;*/
  /*overflow: scroll;*/
  /*padding-top: 5px;*/
  /*margin: 5px 5px 5px 40px;*/
  /*padding-bottom: 30px;*/
  /*border-bottom: 1px solid lightgrey;*/
}

.cleanDisplayMenuItem{
  /*border-right: 1px solid lightgrey;*/
  /*border-bottom: 1px solid lightgrey;*/
  border: 1px solid lightgrey;
  cursor: pointer;
  padding: 10px 5px 10px 7px;
  background-color: white;
  /*width: 50%;*/
  /*border-radius: 10px 0 0 10px;*/
}

.cleanDisplayMenuItemSelected{
  /*border-right: none;*/
  /*border-left: 1px solid lightgrey;*/
  /*border-top: 1px solid lightgrey;*/
  /*border-bottom: none;*/
  background-color: #0081ff;
  color: white;
  /*border: 1px solid lightgrey;*/
}

.cleanDisplayMenuItemColName{
  font-size: 105%;
  font-weight: bold;
  /*padding-left: 15px;*/
  margin-top: 5px;
}

.cleanDisplayMenuItemColInfo{
}

.cleanDisplayMasterTableResults{
  overflow-x: scroll;
  /*padding: 30px 10px 50px 10px;*/
  grid-area: cleanDisplayMasterTableResults;
  /*border-left: 1px solid lightgrey;*/
  /*padding-left: 50px;*/
  /*border-radius: 0 10px 10px 0;*/
}

.formattedResultsHeader, .splitResultsHeader{
  border: 1px solid lightgrey;
  padding: 10px;
  background-color: whitesmoke;
}

.splitResultsCell{
  border: 1px solid lightgrey;
}

.formattedResultsCell{
  border: 1px solid lightgrey;
  /*padding: 10px;*/
}

.successCell{
  background-color: lightgreen;
}

.errorCell{
  background-color: lightcoral;
}

.sampleDescText{
  /*font-style: italic;*/
  /*font-size: 120%;*/
  display: block;
  color: gray;
}

.formattedResultsTable{
  /*max-width: 400px;*/
  /*width: 100% !important;*/
  /*width: 90%;*/
}

.formattedResultsDiv{
  /*overflow-x: scroll;*/
}

.historyFileSegment{
  background-color: #0081ff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 40px;
  color: white;
}
.historyIndex{
  background: url("../src/SharedComponents/history_page.svg") no-repeat;
  background-size: 494px 390px;
  background-position: 0px 400px;

}
.historyFileSegmentGrid{
  display: grid;
  grid-template-columns: 60% 40%;
}

.creditsSegment{
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2), 0 2px 4px 0 rgba(0,0,0,0.19);
}

.creditsSegmentTop {
  /*width: 400px;*/
}

.supportIndex{
  padding: 50px 15px 200px 15px;
  min-height: 100vh;
}

.creditsIndex{
  background: url("../src/SharedComponents/credits.svg") no-repeat;
  background-size: 494px 390px;
  background-position: 100% 0px;
}

.inputError{
  color: red
}

.supportFormLabel{
  font-size: 110%;
}

.requiredLabel{
  padding-left: 3px;
  padding-right: 3px;
  color: red;
}

.cleaningStyleDisclaimer{
  border: 1px solid lightgrey;
  padding: 10px 0 10px 10px;
}

.buttonRaw {
  border: none;
  padding: 5px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

#supportFormClearAllButton{
  float: right;
  border-radius: 12px;
  font-size: 80%;
  cursor: pointer;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);

}

#subjectCharLeft{
  color: gray;
  font-style: italic;
}

#supportUploadFiles{
  background-color: #0088ff;
  color: white;
}

#supportUploadFiles:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

.footerIndex{
  /*background-color: #f5f5f5;*/
  background-color: #0071bc;
  color: whitesmoke;
}

.fullFooter{
  padding: 30px 10px 40px 10px;
}

.fullFooterBox h3{
  padding-bottom: 15px;
  font-weight: 300;
}

.apiSocialFooterBox img{
  padding-top: 20px;
  padding-bottom: 20px;
  float: left;
}


.apiSocialFooterBox span{
  float: left;
  margin-bottom: 15px;
}

.footerItem{
  cursor: pointer;
  text-decoration: none;
  font-size: 130%;
  color: white;
  display: block;
  margin-bottom: 15px;
}

.footerItem:hover{
  color: black;
  text-decoration: none;
}

.resetStepsButton{
  padding-top: 50px;
}

.deleteHistoryFileText{
  color: yellow;
  text-align: center;
  margin-top: 7px;
  cursor: pointer;
}

.deleteHistoryFileText:hover{
  color: white;
  font-weight: bold;
}


#whatElseCanYouDo{
  /*background: url("../src/SharedComponents/LandingBg.svg") no-repeat;*/
  /*background-size: 100%;*/
  padding-top: 100px;
  padding-bottom: 100px;
}

.helpSectionButton{
  text-align: center;
  padding: 20px 5px 20px 5px;
  margin: 5px 0 5px 0;
  border: 1px solid lightgrey;
  border-radius: 5px;
  cursor: pointer;
}

.helpSectionButtonSelected{
  background-color: #0081ff;
  color: white;
  border-color: #0081ff;
  font-weight: 900;
}

.navbarMobileIcon{
  cursor: pointer;
  padding-right: 25px;
  padding-top: 10px;
  font-size: 200%;
}

.addressOptionsBox{
  border: 1px solid lightgrey;
  border-radius: 15px;
  padding: 25px;
}

.addressOptionsBox > label{
  margin-left: 15px;
  cursor: pointer;
}

.addressOptionsBox > p{
  font-size: 125%;
}

.addressColumnDropTarget{
  /*background-color: lightgrey;*/
  /*height: 150px;*/
  /*padding: 10px 0 10px 0;*/
  /*height: 200px;*/
  /*width: auto;*/
  /*margin-bottom: 15px;*/
}

.addressColumnDropSource{
  border: 1px solid black;
  /*margin-bottom: 15px;*/
  border-radius: 15px;
  padding-left: 5px;
  padding-right: 2px;
  /*cursor: */
  /*padding: 5px;*/
  /*height: auto;*/
}

.addressColumnBox{
  border: 1px solid lightgrey;
  border-radius: 15px;
  padding: 15px;
  margin-top: 25px;
}

.addressColumnTags{
  margin-bottom: 15px;
  border-radius: 15px;
  background-color: lightgrey;
  padding: 10px 10px 10px 5px;
}

.addCleaningStepsButtonDiv{
  margin-top: 25px;
}

.addCleaningStepsButton{
  border-radius: 50%;
  /*float: right;*/
}

.cleaningStepsBox{
  border: 1px solid lightgrey;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 20px;
}

.cleaningStep{
  list-style-type: none;
}

.removeStepIcon{
  cursor: pointer;
  color: red;
}

.downloadAllColumnLinks{
  color: gold;
  cursor: pointer;
  padding-bottom: 10px;
}

.textAreaNoShadow{
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 7px;
}

#addressTypeLandingForward, #addressTypeLandingReverse{
  font-size: 150%;
  cursor: pointer;
}

#addressTypeLandingForwardLabel, #addressTypeLandingReverseLabel{
  padding-left: 10px;
  font-size: 125%;
  /*font-weight: bold;*/
  cursor: pointer;
}

.liveDemoBox{
  padding: 0 15px 0 30px;
}

.landingPageDemoResults{
  overflow-x: scroll;
}

.emailUnsubscribeIndex{
  min-height: 100vh;
  text-align: center;
  margin-top: 100px;
}

.emailUnsubscribeIndex > h4{
  margin-bottom: 50px;
}
.guidesIndex{
  min-height: 100vh;

}
.guidesPostIndex{
  min-height: 100vh;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 150px;
  margin-top: 50px;

}
.guidesPostIndexHeader{
  grid-area: displayHeader;
  padding-bottom: 25px;
}

.guidesCard{
  font-family: museo sans rounded, "sans-serif";
  color: black;
  padding: 25px 10px 25px 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  margin-top: 25px;
  border-radius: 10px;
  background-color: whitesmoke;
  position: relative;
  margin-left: 40px;
  width:auto;
  height:auto;
  text-align: center;
}
.guidesCard:hover{
  box-shadow: 0 8px 32px 0 rgba(0,0,0,0.2);
}
.guidesCardImg{
  width: 50%;
  height:50%;
  margin: 0 auto;
  padding-top:25px;
}
.guidesCardBg{
  background-color: orangered;
  padding-top: 20px;
  border-radius: 10px;
  padding-bottom: -40px;
  position: relative;
}
.guidesLayout{
  margin-right: 50px;
  margin-bottom: 250px;
  text-align: center;
}
.guidesDisplayIndex{
  width: 50%;
  margin: 0 auto;
  margin-bottom: 150px;
  margin-top: 50px;

}
.guidesDisplayIndex li{
  font-size: 125%;
  margin-bottom: 10px;
}
.guidesDisplayIndex p {
  font-size: 125%;
  margin-bottom: 40px;
}

.guidesDisplayHeader{
  grid-area: displayHeader;
  padding-bottom: 25px;
}
.tutorialShare{
  border: 1px solid lightgrey;
  border-radius: 15px;
  padding: 25px;
  /*height: 100px;*/
  /*width: 50px;*/
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  float: left;
  margin: 10px 0 0 50px;
  position: fixed;
  text-align: center;
}

.pricingTypeSelector{
  /*margin-top: 50px;*/
  /*margin-bottom: 15px;*/
}

.monthlyPricingSelector, .payAsYouGoSelector{
  border: 1px solid lightgrey;
  padding: 10px;
  cursor: pointer;
  font-size: 130%;
}

.monthlyPricingSelector{
  border-radius: 10px 0px 0px 10px;
}

.payAsYouGoSelector{
  border-radius: 0px 10px 10px 0px;
}

.payAsYouGoPricingDiv{
  margin-top: 25px;
}

.monthlyPricingDiv{
  margin: 25px 150px 25px 150px;
}

.pricingPageMainDiv > h4{
  text-align: center;
}

.pricingPageMainDiv{
  margin: 40px 5% 0 5%;
  /*margin-top: 40px;*/
  padding: 20px 20px 0 20px;
  border: 1px solid lightgrey;
}

.pricingPageBlock{
  /*height: 75px;*/
  /*width: 100px;*/
  border-radius: 15px;
  /*border: 1px solid lightgrey;*/
  margin: 0 auto;
  margin-top: -50px;
  padding: 25px;
}

.pricingPageBlockFeatureDiv{
  text-align: left;
  padding: 10px;
  cursor: pointer;
}

.pricingPageBlockFeatureDiv:hover{
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


#basicPricingPageBlock{
  background-color: #a2d5fc;
}

#proPricingPageBlock{
  background-color: #fbb03b;
}

.proOrBasicTag{
  padding: 10px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.creditsIndexSelection{
  border: 1px solid lightgrey;
  padding: 10px;
}

.creditsIndexSelection > div {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.creditsIndexProSelection, .creditsIndexBasicSelection{
  cursor: pointer;
  border: 1px solid lightgrey;
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
}

.creditsIndexProSelection{
  border-radius: 8px 0 0 8px;
}

.creditsIndexBasicSelection{
  border-radius: 0 8px 8px 0;
}

.downloadDisplayIndexMasterTable{
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 15px;
}

.monthlyPricingCard{
  /*border: 1px solid lightgrey;*/
  /*padding: 10px;*/
  background: whitesmoke;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.monthlyPricingCardHeader{
  background-color: lightgrey;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: gray;
  font-size: 110%;
  font-weight: 600;
}

.monthlyPricingCardSign{
  padding-bottom: 50px;
  font-size: 125%;
}

.monthlyPricingCardMoney{
  font-size: 350%;
  color: darkblue;
  font-weight: 200;
}

.monthlyPricingMonth{
  font-size: 125%;
}

.monthlyPricingCardPrice, .monthlyPricingCardFeatures {
  padding-left: 20px;
  padding-top: 10px;
  padding-right: 20px;
  color: gray;
}

.monthlyAccountInfoBox{
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 20px 10px 20px 10px;
}

.monthlyAccountInfoBox > h2{
  text-align: center;
}

.monthlyAccountInfoFeatures span{
  display: block;
}

.monthlyAccountInfoFeatures > span:first-of-type, span:nth-of-type(3){
  font-size: 120%;
  font-weight: 500;
  margin-top: 15px;
}

.monthlyAccountInfoFeatures > span:nth-of-type(2){
  font-size: 90%;
}

.monthlyAccountInfoHeader{
  margin-bottom: 5px;
}

.monthlyPricingCardFeatures{
  text-align: left;
  padding-bottom: 20px;
  padding-left: 30px;
}

.monthlyPricingCardRequests{
  padding-bottom: 10px;
  display: block;
}

.monthlyPricingCardSupport > span, .monthlyPricingCardRequests > span{
  border-bottom: 1px dotted black;
  cursor: context-menu;
}

.scheduleDemoButton, .updatedCTA{
  border: 0;
  box-shadow: none;
  /*padding: 5px 25px 10px 25px;*/
  padding: 15px 25px 15px 25px;
  /*padding: 5px 25px 10px 25px;*/
  /*font-size: 24px;*/
  font-size: 18px;
  /*font-weight: 300;*/
  font-weight: 500;
  cursor: pointer;
  border-radius: 2px;
}

.scheduleDemoButton{
  background: orangered;
  color: white;
  margin-left: 5px;
  border: 1px solid orange;
}
.updatedCTA{
  color: white;
  /*background: linear-gradient(to right, #29abe2, #2e3192);*/
  /*background: linear-gradient(to right, #0081ff, #2e3192);*/
  background-color: #0081ff;
}

.updatedCTA:hover, .scheduleDemoButton:hover{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.howItWorksCard{
  /*height: 534px;*/
  /*width: 100%;*/
  border: 1pt solid #e6e6e6;
}

.navbarItem:hover{
  border-bottom: 5px solid #0081ff;
}

.howItWorksCardCaret, .howItWorksCardHoverCaret{
  text-align: center;
  font-size: 250%;
  cursor: pointer;
}

.howItWorksCardCaret{
  color: grey;
}

.howItWorksCardHoverCaret{
  /*bottom: 0;*/
  color: white;
  /*position: absolute;*/
  /*left: 50%;*/
}

.howItWorksCardCaret:hover{
  background-color: whitesmoke;
}

.howItWorksCardHoverCaret:hover{
  background-color: lightgrey;
}

.moreInfoCard{
  text-align: left;
  border-radius: 2px;
  /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /*box-shadow: 0 2px 2px 0 #0081ff;*/
  padding: 15px 25px 15px 25px;
  margin: 0 20px 0 20px;
}

.moreInfoCard > p{
  font-size: 110%;
  padding: 5px 0 5px 0;
  text-align: left;
}

.landingSecurityFeatures{
  background-color: white;
  padding-top: 25px;
  padding-bottom: 25px;
}

.landingPageBottomSignUp{
  background: url("../src/LandingPage/Illustrations/CleanBottomBackground.svg") no-repeat;
  background-size: 100% auto;
}

.segment-no-margin{
  background: #fff;
  border-radius: 2px;
  padding: 20px;
}

.segment-raised{
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

span.step {
  background: black;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 5px;
  text-align: center;
  width: 1.6em;
}

.carousel{
  display: grid;
  grid-template-columns: 5% 90% 5%;
  grid-template-rows: auto auto;
  grid-template-areas: "carouselLeft carouselContent carouselRight"
  "carouselLeft carouselIndicator carouselRight";
}

.carouselLeft{
  grid-area: carouselLeft;
  position: relative;
}

.carouselLeft:hover{
  background-color: rgba(128,128,128, 0.1);
}

.carouselContent{
  grid-area: carouselContent;

}

.carouselRight{
  grid-area: carouselRight;
  position: relative;
}

.carouselRight:hover{
  background-color: rgba(128,128,128, 0.1);
}

.carouselRight > div{
  float: right;
  padding-left: 5%;
  /*font-size: 250%;*/
}

.carouselIndicator{
  grid-area: carouselIndicator;
  /*margin: 0 auto;*/
  width: 100%;
  bottom: 0;
  position: absolute;
  text-align: center;
}

.carouselIndicator > div{
  text-align: center;
  padding-top: 50px;
}

#carouselLeftArrow{
  position: absolute;
  top: 50%;
}

#carouselRightArrow{
  position: absolute;
  top: 50%;
}

#socialProofIndex{
  padding: 80px 0 100px 0;
}

.socialProofIndexBackgroundColor{
  background-color: rgba(26, 113, 198, .12);
  /*opacity: .12;*/
}

.landingPagePricingIndexRow{
  background: url("../src/LandingPage/LandingPagePricing/LandingPricingBackground.svg") no-repeat;
  background-size: 100% auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.landingPagePricingCard{
  background-color: white;
  padding: 50px 20px 50px 20px;
  margin: 0 50px 0 50px;
  border: 4px solid #0081ff;
}

.landingPagePricingIndexFreeRow {
  margin-top: 15px;
}
.landingPagePricingIndexFreeRow > span{
  background-color: black;
  color: white;
  border-radius: 15px;
  padding: 10px;
}

.landingPagePricingIndexFreeRow > div {
  margin-top: 15px;
  font-size: 125%;
}

.columnResultsNameInfo{
  /*margin-bottom: 25px;*/
  border: 1px solid lightgrey;
  padding: 10px;
  border-radius: 3px;
}

.multipleColumnNamesDropdownBtn{
  margin-top: 10px;
  background-color: #0081ff;
  color: white;
  padding: 10px;
  font-size: 14px;
}

.multipleColumnNamesHeading{

}

/*.columnResultsNameInfo > span:nth-of-type(2){*/
  /*font-size: 150%;*/
  /*font-weight: bold;*/
/*}*/

.proVsBasicCopy{
  /*box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.19);*/
  box-shadow: 2px 2px 5px grey;
  padding: 15px;
  margin-bottom: 15px;
}

.proVsBasicCopy > h4, p{
  margin: 0;
}

.columnResultsNameInfo > span:first-of-type > span{
  float: right;
}

.columnResultsUniqueValueDisplay{
  text-align: center;
}

.columnResultsUniqueValueDisplayDiv{
    border: 1px solid lightgrey;
    display: block;
    width: 150px;
    float: right;
    padding: 10px;
    border-radius: 50%;
}

.columnResultsUniqueValueDisplayDiv > span:first-of-type{
  color: green;
  font-size: 40px;
  margin-right: 25px;
}

.monthlyAccountInfoSeeOtherPlans{
  text-align: center;
  border: 1px solid lightgrey;
  padding: 25px;
  margin: 0 25px 0 25px;
  font-size: 150%;
  background-color: whitesmoke;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  color: blue;
}

.monthlyAccountInfoSeeOtherPlans:hover{
  background-color: #DCDCDC;
  color: cornflowerblue;
}

.apiDocsIndex{
  min-height: 100vh;
  display: grid;
  grid-template-columns: 225px auto;
  grid-template-rows: auto;
  grid-template-areas: "apiDocsMenu apiDocsDisplay";
  margin-bottom: 150px;
}

.apiDocsMenu{
  grid-area: apiDocsMenu;
  border-right: 1px solid lightgrey;
  box-shadow: 2px 2px 5px grey;
  padding: 25px;
  overflow: auto;
  /*overflow-y: auto;*/
  position: fixed;
  height: 100%;
}

.apiDocsDisplay{
  grid-area: apiDisplay;
  padding: 25px;
  padding-top: 75px;
  height: 100%;
  overflow: auto;
  position: relative;
}

.apiDocsMenuItem{
  cursor: pointer;
  font-size: 110%;
}

.apiDocsMenuItemSelected{
  cursor: pointer;
  font-size: 110%;
  color: darkblue;
  background-color: #dae7f4;
  padding: 5px;
  border-radius: 8px;
}

.apiDocsSubMenuItem{
  font-size: 95%;
  margin-left: 15px;
}

.apiDisplaySegmentBody{
  padding-top: 25px;
}

.apiSectionDesc{
  font-weight: 400;
  padding-bottom: 50px;
}

.urlBoxHeader{
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #343b43;
  color: lightgrey;
  border-radius: 8px 8px 0 0;
  font-size: 90%;
}

.urlBoxLink{
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #677685;
  color: white;
  border-radius: 0 0 8px 8px;
}

.displayBoxHeader{
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px #E0E0E0 solid;
  background-color: #E0E0E0;
  border-radius: 8px 8px 0 0;
}

.displayBoxDesc{
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: whitesmoke;
  border-radius: 0 0 8px 8px;
}

.parameterName{
  font-weight: 800;
}

.parameterRequired{
  color: red;
  font-weight: 500;
}

.parameterOptional{
  color: green;
  font-weight: 500;
}

.parameterSecondLine{
  /*margin-top: -15px;*/
  font-size: 80%;
}

.parameterDesc{
  /*margin-top: -10px;*/
}

.parameterType{
  font-style: italic;
}

.apiDocSection{
  border-radius: 8px 8px 8px 8px;
  padding: 10px;
  border: 1px lightgrey solid;
}

.sectionHover:hover{
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.headerWithCloseDiv{
  display: grid;
  grid-template-columns: 95% 5%;
  grid-template-rows: auto;
}

.closeIconClass{
  height: 25px;
  width: 25px;
  float: right;
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
}

.closeIconClass:hover{
  background-color: #DCDCDC;
  border-radius: 50%;
}

.closeIconClass > i{
  padding: 0;
}